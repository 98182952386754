import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import gsap from "gsap";
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP);

function NavgationBar() {
  const [parentNav, setParentNav] = useState(""); // First Level
  const [subNavLinks, setSubNavLinks] = useState(null); // Data for the current level
  const [navHistory, setNavHistory] = useState([]); // History stack to track navigation

  const subNavLinksData = {
    floorPlans: [
      { name: "Level 1", subSubNav: [
        { name: "Unit: 101", url: "floor-1-unit-101" },
        { name: "Unit: 102", url: "floor-1-unit-102" },
        { name: "Unit: 103", url: "floor-1-unit-103" },
        { name: "Unit: 104", url: "floor-1-unit-104" },
        { name: "Unit: 105", url: "floor-1-unit-105" },
        { name: "Unit: 106", url: "floor-1-unit-106" },
        { name: "Unit: 107", url: "floor-1-unit-107" },
        { name: "Unit: 108", url: "floor-1-unit-108" },
        { name: "Unit: 109", url: "floor-1-unit-109" },
        { name: "Unit: 110", url: "floor-1-unit-110" },
        { name: "Unit: 111", url: "floor-1-unit-111" },
        { name: "Unit: 112", url: "floor-1-unit-112" },
        { name: "Unit: 113", url: "floor-1-unit-113" },
        { name: "Unit: 114", url: "floor-1-unit-114" },
        { name: "Unit: 115", url: "floor-1-unit-115" },
        { name: "Unit: 116", url: "floor-1-unit-116" },
        { name: "Unit: 117", url: "floor-1-unit-117" },
        { name: "Unit: 118", url: "floor-1-unit-118" },
        { name: "Unit: 119", url: "floor-1-unit-119" },
        { name: "Unit: 120", url: "floor-1-unit-120" },
        { name: "Unit: 121", url: "floor-1-unit-121" },
        { name: "Unit: 122", url: "floor-1-unit-122" }
      ] },
      { name: "Level 2", subSubNav: [
        { name: "Unit: 201", url: "floor-2-unit-201" },
        { name: "Unit: 202", url: "floor-2-unit-202" },
        { name: "Unit: 203", url: "floor-2-unit-203" },
        { name: "Unit: 204", url: "floor-2-unit-204" },
        { name: "Unit: 205", url: "floor-2-unit-205" },
        { name: "Unit: 206", url: "floor-2-unit-206" },
        { name: "Unit: 207", url: "floor-2-unit-207" },
        { name: "Unit: 208", url: "floor-2-unit-208" },
        { name: "Unit: 209", url: "floor-2-unit-209" },
        { name: "Unit: 210", url: "floor-2-unit-210" },
        { name: "Unit: 211", url: "floor-2-unit-211" },
        { name: "Unit: 212", url: "floor-2-unit-212" },
        { name: "Unit: 213", url: "floor-2-unit-213" },
        { name: "Unit: 214", url: "floor-2-unit-214" },
        { name: "Unit: 215", url: "floor-2-unit-215" },
        { name: "Unit: 216", url: "floor-2-unit-216" },
        { name: "Unit: 217", url: "floor-2-unit-217" },
        { name: "Unit: 218", url: "floor-2-unit-218" },
        { name: "Unit: 219", url: "floor-2-unit-219" },
        { name: "Unit: 220", url: "floor-2-unit-220" },
        { name: "Unit: 221", url: "floor-2-unit-221" },
        { name: "Unit: 222", url: "floor-2-unit-222" },
        { name: "Unit: 223", url: "floor-2-unit-223" },
        { name: "Unit: 224", url: "floor-2-unit-224" }
      ] },
      { name: "Level 3", subSubNav: [
        { name: "Unit: 301", url: "floor-3-unit-301" },
        { name: "Unit: 302", url: "floor-3-unit-302" },
        { name: "Unit: 303", url: "floor-3-unit-303" },
        { name: "Unit: 304", url: "floor-3-unit-304" },
        { name: "Unit: 305", url: "floor-3-unit-305" },
        { name: "Unit: 306", url: "floor-3-unit-306" },
        { name: "Unit: 307", url: "floor-3-unit-307" },
        { name: "Unit: 308", url: "floor-3-unit-308" },
        { name: "Unit: 309", url: "floor-3-unit-309" },
        { name: "Unit: 310", url: "floor-3-unit-310" },
        { name: "Unit: 311", url: "floor-3-unit-311" },
        { name: "Unit: 312", url: "floor-3-unit-312" },
        { name: "Unit: 313", url: "floor-3-unit-313" },
        { name: "Unit: 314", url: "floor-3-unit-314" },
        { name: "Unit: 315", url: "floor-3-unit-315" },
        { name: "Unit: 316", url: "floor-3-unit-316" },
        { name: "Unit: 317", url: "floor-3-unit-317" },
        { name: "Unit: 318", url: "floor-3-unit-318" },
        { name: "Unit: 319", url: "floor-3-unit-319" },
        { name: "Unit: 320", url: "floor-3-unit-320" },
        { name: "Unit: 321", url: "floor-3-unit-321" },
        { name: "Unit: 322", url: "floor-3-unit-322" },
        { name: "Unit: 323", url: "floor-3-unit-323" },
        { name: "Unit: 324", url: "floor-3-unit-324" },
        { name: "Unit: 325", url: "floor-3-unit-325" },
        { name: "Unit: 326", url: "floor-3-unit-326" },
        { name: "Unit: 327", url: "floor-3-unit-327" },
        { name: "Unit: 328", url: "floor-3-unit-328" },
        { name: "Unit: 329", url: "floor-3-unit-329" }
      ] },
      { name: "Level 4", subSubNav: [
        { name: "Unit: 401", url: "floor-4-unit-401" },
        { name: "Unit: 402", url: "floor-4-unit-402" },
        { name: "Unit: 403", url: "floor-4-unit-403" },
        { name: "Unit: 404", url: "floor-4-unit-404" },
        { name: "Unit: 405", url: "floor-4-unit-405" },
        { name: "Unit: 406", url: "floor-4-unit-406" },
        { name: "Unit: 407", url: "floor-4-unit-407" },
        { name: "Unit: 408", url: "floor-4-unit-408" },
        { name: "Unit: 409", url: "floor-4-unit-409" },
        { name: "Unit: 410", url: "floor-4-unit-410" },
        { name: "Unit: 411", url: "floor-4-unit-411" },
        { name: "Unit: 412", url: "floor-4-unit-412" },
        { name: "Unit: 413", url: "floor-4-unit-413" },
        { name: "Unit: 414", url: "floor-4-unit-414" },
        { name: "Unit: 415", url: "floor-4-unit-415" },
        { name: "Unit: 416", url: "floor-4-unit-416" },
        { name: "Unit: 417", url: "floor-4-unit-417" },
        { name: "Unit: 418", url: "floor-4-unit-418" },
        { name: "Unit: 419", url: "floor-4-unit-419" },
        { name: "Unit: 420", url: "floor-4-unit-420" },
        { name: "Unit: 421", url: "floor-4-unit-421" },
        { name: "Unit: 422", url: "floor-4-unit-422" },
        { name: "Unit: 423", url: "floor-4-unit-423" },
        { name: "Unit: 424", url: "floor-4-unit-424" },
        { name: "Unit: 425", url: "floor-4-unit-425" },
        { name: "Unit: 426", url: "floor-4-unit-426" },
        { name: "Unit: 427", url: "floor-4-unit-427" },
        { name: "Unit: 428", url: "floor-4-unit-428" },
        { name: "Unit: 429", url: "floor-4-unit-429" },
        { name: "Unit: 430", url: "floor-4-unit-430" },
        { name: "Unit: 431", url: "floor-4-unit-431" },
        { name: "Unit: 432", url: "floor-4-unit-432" },
        { name: "Unit: 433", url: "floor-4-unit-433" },
        { name: "Unit: 434", url: "floor-4-unit-434" },
        { name: "Unit: 435", url: "floor-4-unit-435" },
        { name: "Unit: 436", url: "floor-4-unit-436" },
        { name: "Unit: 437", url: "floor-4-unit-437" },
        { name: "Unit: 438", url: "floor-4-unit-438" }
      ] },
      { name: "Level 5", subSubNav: [
        { name: "Unit: 501", url: "floor-5-unit-501" },
        { name: "Unit: 502", url: "floor-5-unit-502" },
        { name: "Unit: 503", url: "floor-5-unit-503" },
        { name: "Unit: 504", url: "floor-5-unit-504" },
        { name: "Unit: 505", url: "floor-5-unit-505" },
        { name: "Unit: 506", url: "floor-5-unit-506" },
        { name: "Unit: 507", url: "floor-5-unit-507" },
        { name: "Unit: 508", url: "floor-5-unit-508" },
        { name: "Unit: 509", url: "floor-5-unit-509" },
        { name: "Unit: 510", url: "floor-5-unit-510" },
        { name: "Unit: 511", url: "floor-5-unit-511" },
        { name: "Unit: 512", url: "floor-5-unit-512" },
        { name: "Unit: 513", url: "floor-5-unit-513" },
        { name: "Unit: 514", url: "floor-5-unit-514" },
        { name: "Unit: 515", url: "floor-5-unit-515" },
        { name: "Unit: 516", url: "floor-5-unit-516" },
        { name: "Unit: 517", url: "floor-5-unit-517" },
        { name: "Unit: 518", url: "floor-5-unit-518" },
        { name: "Unit: 519", url: "floor-5-unit-519" },
        { name: "Unit: 520", url: "floor-5-unit-520" },
        { name: "Unit: 521", url: "floor-5-unit-521" },
        { name: "Unit: 522", url: "floor-5-unit-522" },
        { name: "Unit: 523", url: "floor-5-unit-523" },
        { name: "Unit: 524", url: "floor-5-unit-524" },
        { name: "Unit: 525", url: "floor-5-unit-525" },
        { name: "Unit: 526", url: "floor-5-unit-526" },
        { name: "Unit: 527", url: "floor-5-unit-527" },
        { name: "Unit: 528", url: "floor-5-unit-528" },
        { name: "Unit: 529", url: "floor-5-unit-529" },
        { name: "Unit: 530", url: "floor-5-unit-530" },
        { name: "Unit: 531", url: "floor-5-unit-531" },
        { name: "Unit: 532", url: "floor-5-unit-532" },
        { name: "Unit: 533", url: "floor-5-unit-533" },
        { name: "Unit: 534", url: "floor-5-unit-534" },
        { name: "Unit: 535", url: "floor-5-unit-535" },
        { name: "Unit: 536", url: "floor-5-unit-536" },
        { name: "Unit: 537", url: "floor-5-unit-537" },
        { name: "Unit: 538", url: "floor-5-unit-538" }
      ] },
      { name: "Level 6", subSubNav: [
        { name: "Unit: 601", url: "floor-6-unit-601" },
        { name: "Unit: 602", url: "floor-6-unit-602" },
        { name: "Unit: 603", url: "floor-6-unit-603" },
        { name: "Unit: 604", url: "floor-6-unit-604" },
        { name: "Unit: 605", url: "floor-6-unit-605" },
        { name: "Unit: 606", url: "floor-6-unit-606" },
        { name: "Unit: 607", url: "floor-6-unit-607" },
        { name: "Unit: 608", url: "floor-6-unit-608" },
        { name: "Unit: 609", url: "floor-6-unit-609" },
        { name: "Unit: 610", url: "floor-6-unit-610" },
        { name: "Unit: 611", url: "floor-6-unit-611" },
        { name: "Unit: 612", url: "floor-6-unit-612" },
        { name: "Unit: 613", url: "floor-6-unit-613" },
        { name: "Unit: 614", url: "floor-6-unit-614" },
        { name: "Unit: 615", url: "floor-6-unit-615" },
        { name: "Unit: 616", url: "floor-6-unit-616" },
        { name: "Unit: 617", url: "floor-6-unit-617" },
        { name: "Unit: 618", url: "floor-6-unit-618" },
        { name: "Unit: 619", url: "floor-6-unit-619" },
        { name: "Unit: 620", url: "floor-6-unit-620" },
        { name: "Unit: 621", url: "floor-6-unit-621" },
        { name: "Unit: 622", url: "floor-6-unit-622" },
        { name: "Unit: 623", url: "floor-6-unit-623" },
        { name: "Unit: 624", url: "floor-6-unit-624" },
        { name: "Unit: 625", url: "floor-6-unit-625" },
        { name: "Unit: 626", url: "floor-6-unit-626" },
        { name: "Unit: 627", url: "floor-6-unit-627" },
        { name: "Unit: 628", url: "floor-6-unit-628" },
        { name: "Unit: 629", url: "floor-6-unit-629" },
        { name: "Unit: 630", url: "floor-6-unit-630" },
        { name: "Unit: 631", url: "floor-6-unit-631" },
        { name: "Unit: 632", url: "floor-6-unit-632" }
      ] },
      { name: "Level 8-30", subSubNav: [
        { name: "Unit: 1", url: "floor-8-30-unit-1" },
        { name: "Unit: 2", url: "floor-8-30-unit-2" },
        { name: "Unit: 3", url: "floor-8-30-unit-3" },
        { name: "Unit: 4", url: "floor-8-30-unit-4" },
        { name: "Unit: 5", url: "floor-8-30-unit-5" },
        { name: "Unit: 6", url: "floor-8-30-unit-6" },
        { name: "Unit: 7", url: "floor-8-30-unit-7" },
        { name: "Unit: 8", url: "floor-8-30-unit-8" }
      ] },
    ],
    colorSchemes: [
      { name: "Master Bedroom", url: "/master-bedroom" },
      { name: "Master Bedroom Closet", url: "/master-closet" },
      { name: "Bedroom", url: "/bedroom" },
      { name: "Bathroom", url: "/bathroom" },
      { name: "Bathroom 2", url: "/bathroom-2" },
      { name: "Living Area", url: "/living-area" },
      { name: "Living Area 2", url: "/living-area-2" },
      { name: "Laundry Area", url: "/laundry-area" },
      { name: "Kitchen 1", url: "/kitchen-1" },
      { name: "Kitchen 2", url: "/kitchen-2" },
      { name: "Kitchen 3", url: "/kitchen-3" },
      { name: "Dining Area", url: "/dining-area" },
    ],
    amenties: [
      { name: "Interior", url: "/interior" },
      { name: "Exterior", url: "/exterior" },
    ],
    gallery: [
      { name: "Hero Renderings", url: "/hero-renderings" },
      { name: "Units", url: "/units" },
    ],
    floorView: [
      { name: "Day & Night Views", 
        subSubNav: [
          { name: "Level 8-11", url: "level-8-11" },
          { name: "Level 12-15", url: "level-12-15" },
          { name: "Level 16-19", url: "level-16-19" },
          { name: "Level 20-23", url: "level-20-23" },
          { name: "Level 24-27", url: "level-24-27" },
          { name: "Level 28-31", url: "level-28-31" },
        ]
      }
    ],
    view: [
      {
        name: "Day & Night Views",
        subSubNav: [
          { name: "Bedroom Views", url: "/bedroom-views" },
          { name: "Living Room Views", url: "/living-room-views" },
          { name: "Dining Room Views", url: "/dining-room-views" },
        ],
      },
    ],
  };

  useEffect(() => {
    gsap.fromTo(
      ".nav-link span",
      {
        bottom: "10px",
      },
      {
        stagger: 0.05,
        bottom: "0px",
        opacity: 1,
        duration: 0.5,
      }
    );
  }, [parentNav]);

  const handleNavClick = (nav, subNav = null) => {
    setNavHistory([...navHistory, parentNav]);
    setParentNav(nav);
    setSubNavLinks(subNav || subNavLinksData[nav]);
    gsap.to(".nav-link span", {
      bottom: "-15px",
      opacity: 0,
      stagger: function (index, target, list) {
        if( list.length < 12 ){
          return index * 0.05;
        }else{
          return 0;
        }
      },
    });
  };

  const handleBackClick = () => {
    const previousNav = navHistory[navHistory.length - 1];
    const previousSubNav = subNavLinksData[previousNav];
    setParentNav(previousNav);
    setSubNavLinks(previousSubNav);
    setNavHistory(navHistory.slice(0, -1));
    gsap.to(".nav-link span", {
      bottom: "-15px",
      opacity: 0,
      stagger: function (index, target, list) {
        if( list.length < 12 ){
          return index * 0.05;
        }else{
          return 0;
        }
      },
    });
  };

  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary"
      bg="dark"
      data-bs-theme="dark"
    >
      <Container fluid>
        <Row className="w-100 align-items-center">
          <Col lg={2}>
            <Navbar.Brand href="#home">
              <img
                style={{ width: "120px"}}
                src={`${process.env.PUBLIC_URL}/images/skyliving.jpeg`}
                alt="logo"
              />
            </Navbar.Brand>
          </Col>
          <Col lg={10}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              {!parentNav ? (
                <Nav className="main-menu">
                  <Nav.Link href="#home">
                    <span>Home</span>
                  </Nav.Link>
                  <Nav.Link onClick={() => handleNavClick("floorPlans")}>
                    <span>
                      Floor Plans <FontAwesomeIcon icon={faPlus} />
                    </span>
                  </Nav.Link>
                  <Nav.Link onClick={() => handleNavClick("amenties")}>
                    <span>
                      Amenities <FontAwesomeIcon icon={faPlus} />
                    </span>
                  </Nav.Link>
                  <Nav.Link onClick={() => handleNavClick("gallery")}>
                    <span>
                      Gallery <FontAwesomeIcon icon={faPlus} />
                    </span>
                  </Nav.Link>
                  <Nav.Link onClick={() => handleNavClick("colorSchemes")}>
                    <span>
                      Color Scheme <FontAwesomeIcon icon={faPlus} />
                    </span>
                  </Nav.Link>
                  <Nav.Link onClick={() => handleNavClick("floorView")}>
                    <span>
                      Floor Views <FontAwesomeIcon icon={faPlus} />
                    </span>
                  </Nav.Link>
                  <Nav.Link onClick={() => handleNavClick("view")}>
                    <span>
                      360 View <FontAwesomeIcon icon={faPlus} />
                    </span>
                  </Nav.Link>
                </Nav>
              ) : (
                <>
                  <button onClick={handleBackClick} className="btn-back-nav">
                    <img src={`${process.env.PUBLIC_URL}/images/left-arrow.png`} alt="back" />
                  </button>
                  <Nav className={ subNavLinksData[parentNav] ? "me-auto sub-menu" : "me-auto sub-menu sub-level-2" }>
                    {subNavLinks &&
                      subNavLinks.map((subNav, index) =>
                        subNav.subSubNav ? (
                          <Nav.Item key={index}>
                            <Nav.Link
                              onClick={() =>
                                handleNavClick(subNav.name, subNav.subSubNav)
                              }
                            >
                              <span>
                                {subNav.name} <FontAwesomeIcon icon={faPlus} />{" "}
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        ) : (
                          <Nav.Item key={index}>
                            <Nav.Link href={subNav.url}>
                              <span>{subNav.name}</span>
                            </Nav.Link>
                          </Nav.Item>
                        )
                      )}
                  </Nav>
                </>
              )}
            </Navbar.Collapse>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

export default NavgationBar;
