import './App.css';
import NavgationBar from './NavgationBar';

function App() {
  return (
    <div className="App">
      <div className="page-wrapper">
        <div className="container-full">
          <div className="image-wrapper"></div>
          <div className="nav-wrapper">
            <NavgationBar />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
